
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import styled from '@emotion/styled';
//import logo from './img/honeycomb.png';
import smallLogo from './img/honeycomb.png';
import logo from './img/honeycutt.svg';
import nc from './img/nc.png';
import react_logo from './img/react.webp';
import aws from './img/aws.png';
import firebase from './img/firebase.png';
import mongodb from './img/mongodb.png';
import node from './img/node.png';
import typescript from './img/typescript.png';
import angular from './img/angular.png';
import expo_light from './img/logo-type-b.png';
import solidity from './img/solidity.svg';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';



const Container = styled.div`
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (prefers-color-scheme: light) {
    background-color: white;
    color: black;
  }
  @media (prefers-color-scheme: dark) {
    background-color: #323232;
    color: white;
  }
  
`

const Main = styled.div`
padding: 5rem 0;
flex: 1;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const Footer = styled.div`
  width: 100%;
  height: 100px;
  border-top: 1px solid #111;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.85rem;
`

const Copyright = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem; 
`

const Title = styled.div`
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
  text-align: center;
`

const Code = styled.div`
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 2.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
`

const Card = styled.div`
  margin: 1rem;
  flex-basis: 45%;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #111;
  border-radius: 4px;
  transition: color 0.15s ease, border-color 0.15s ease;
  & > h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
  }
  & > p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
  }
`

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  max-width: 800px;
  margin-top: 3rem;
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
`

const Banner = styled.object`
  height: 8em;
  margin: 2em;
`

type LogoProps = {
  height?: string
  margin?: string 
  invert?: boolean
}

const Logo = styled.img<LogoProps>`
  height: ${props => !!props.height ? props.height : '1.25em'};
  margin-left: ${props => !!props.margin ? props.margin : '0.4em'};
  margin-right: ${props => !!props.margin ? props.margin : '0.4em'};
  @media (prefers-color-scheme: dark) {
    filter: ${props => !!props.invert ? 'invert(100%)' : 'none'};
    -webkit-filter: ${props => !!props.invert ? 'invert(100%)' : 'none'};
  }
  
`

type ButtonProps = {
  submit: boolean
}

const Button = styled.div<ButtonProps>`
  cursor: default;
  background: ${props => props.submit ? '#e4e200' : '#ababab'};
  color: #000;
  border: none;
  padding: 0.5em;
  font-size: 10pt;
  width: 150px;
  margin: 1em 0 1em 0;
  align-self: center;
  text-align: center;
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${props => props.submit ? '#e2d600' : '#565656'};
  }
`

const Form = styled.form`
  width: 100%;

  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;

  max-width: 800px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
  & > input {
    width: 100%;
    line-height: 1.2rem;
    border-width: 0 0 1px 0;
    border-color: #909090;
    &:focus {
      outline: none;
    }
    &:hover {
      background: #efefef;
    }
  }
  & > textarea {
    width: 100%;
    height: 60px;
    resize: none;
    border-width: 0 0 1px 0;
    border-color: #909090;
    &:focus {
      outline: none;
    }
    &:hover {
      background: #efefef;
    }
  }
`

const Label = styled.label`
  margin: 1em 0 0.2em 0;
  width: 80px;
`

const Overlay = styled.div`
  position: absolute;
  flex: 1;
  z-index: 5;
  background: rgba(0,0,0,0.7);
`

export default function Home() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [working, setWorking] = useState<boolean>(false);
  const [submit, setSubmit] = useState(false);

  const validateEmail = (input: string) => {
    return !!input && input.length > 0 && input.includes('@')
  }

  useEffect(() => {
    setSubmit(name.length > 0 && validateEmail(email) && message.length > 0)
  }, [name, email, message])

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }
  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }
  const handleMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  }

  const sendContact = async () => {
    if (!submit) return;
    setWorking(true);
    const result = await API.post('formapi', '/contact', {body: {
      name, email, message
    }});
    console.log(result);
    setName('');
    setEmail('');
    setMessage('');
    setWorking(false);
  }

  if (!!working) {

    return (
      <Container>
        <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
      </Container>
    )
  }
  
  return (
    <Container>
      <Main>
        <Title>
          <Code>Honeycutt Software Development</Code>
        </Title>
        <Banner type="image/svg+xml" data={logo} />

        <Grid>
          <Card>
            <h2><img src={react_logo} height="36px" alt="ReactJS Logo" /></h2>
            <p>React is our preferred framework for modern web application development</p>
          </Card>

          <Card>
            <h2><img src={solidity} height="36px" alt="Solidity Logo" /></h2>
            <p>dApps, Web3, Smart Contracts</p>
            <p>The next internet is being created right now</p>
          </Card>

          <Card>
            <h2><img src={node} height="32px" alt="NodeJS Logo" /></h2>
            <p>Node and Express are the backbone of the MERN stack. We love them. </p>
          </Card>

          <Card>
            <h2><img src={typescript} height="36px" alt="Typescript Logo" /></h2>
            <p>
              Typescript is just superior in every way. It makes React and Express development better.
            </p>
          </Card>

          <Card>
            <h2><Logo src={expo_light} height={'24px'} invert alt="Expo Logo" /></h2>
            <p>
              Expo offers unified mobile development for Android and iOS using React Native. 
            </p>
          </Card>

          <Card>
            <h2><img src={angular} height="36px" alt="Angular Logo" /></h2>
            <p>
              Get out of here with that Angular nonsense. We do not do Angular.
            </p>
          </Card>

          <Card>
            <h2><img src={aws} height="32px" alt="AWS Logo" /></h2>
            <p>AWS Amplify allows deep integration of React and React Native applications with AWS services.</p>

          </Card>

          <Card>
            <h2><img src={firebase} height="32px" alt="Firebase Logo" /></h2>
            <p>
              Firebase offers a very nice unified framework for mobile and web development. Depending on project specifics, we often prefer it.
            </p>
          </Card>

        </Grid>
        <Form>
          <Label>
            Name 
          </Label>
          <input type='text' onChange={handleName} value={name} />

          <Label>
            Email
          </Label>
          <input type='text' onChange={handleEmail} value={email} />

          <Label>
            Message
          </Label>
          <textarea onChange={handleMessage} value={message} />
          <Button submit={submit} onClick={sendContact}>Send</Button>
        </Form>

        <Footer>
          Built with <Logo src={react_logo} margin={'0'} alt="ReactJS Logo" height={'24px'} />and <Logo src={aws} alt="ReactJS Logo" height={'16px'} />by
          <Logo src={smallLogo} height={'18px'} />{' in '}<Logo src={nc} invert alt="North Carolina" height={'18px'} />
        </Footer>
        <Copyright>&copy; {new Date().getFullYear()}&nbsp;Honeycutt Software Development, LLC</Copyright>
      </Main>
    </Container>
  )
}